import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import ComplexDiagnostic from "../views/ComplexDiagnostic.vue";
import NetworkDiagnostic from "../views/NetworkDiagnostic.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Oauth from "../views/Oauth.vue";

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/dashboard",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/complex-diagnostic",
        name: "Complex Diagnostic",
        component: ComplexDiagnostic,
    },
    {
        path: "/network-diagnostic",
        name: "Network Diagnostic",
        component: NetworkDiagnostic,
    },
    // {
    //     path: "/virtual-reality",
    //     name: "Virtual Reality",
    //     component: VirtualReality,
    // },
    // {
    //     path: "/rtl-page",
    //     name: "RTL",
    //     component: RTL,
    // },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/oauth",
        name: "Oauth",
        component: Oauth,
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

export default router;
