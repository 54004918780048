<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto d-flex flex-column mx-lg-0 align-items-center">
              <div class="card">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your account and password to sign in</p>
                </div>
                <div class="card-body">
                  <!-- <form role="form"> -->
                  <div class="mx-auto">
                    <div class="form-group">
                      <b-form-select
                        class="form-control form-control-lg"
                        v-model="selected"
                        :options="options"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="mx-auto">
                    <div class="form-group">
                      <input
                        class="form-control form-control-lg"
                        type="text"
                        placeholder="Account"
                        v-model="id"
                      />
                    </div>
                  </div>
                  <div class="mx-auto">
                    <div class="form-group">
                      <input
                        class="form-control form-control-lg"
                        type="password"
                        placeholder="Password"
                        v-model="password"
                      />
                    </div>
                  </div>
                  <div class="mx-auto">
                    <label class="text">
                      1. 개인정보를 제공받는 자 : 경동
                      <br />
                      2. 개인정보를 제공받는 자의 이용목적 : 경동 홈IoT 네트워크
                      연동
                      <br />
                      3. 제공하는 개인정보의 항목 : 경동 홈 네트워크 세대정보
                    </label>
                  </div>
                  <div class="d-flex">
                    <input type="checkbox" id="ckb1" name="agreement" />
                    <label class="txt2" for="ckb1">
                      회원님이 등록하신 세대 디바이스 정보를 제공하는데 동의
                      합니다.
                    </label>
                  </div>
                  <div class="text-center">
                    <argon-button
                      class="mt-4"
                      variant="gradient"
                      color="primary"
                      fullWidth
                      size="lg"
                      @click="login(id, password, selected)"
                      >Sign in</argon-button
                    >
                  </div>
                  <!-- </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { BFormSelect } from "bootstrap-vue-3";
import { ApiManager } from "@/api/apiManager";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
    BFormSelect,
    ArgonButton,
  },
  data() {
    return {
      selected: null,
      options: [],
      password: undefined,
      id: undefined,
    };
  },
  methods: {
    login: async (id, password, complexId) => {
      let loginResult = await ApiManager.getInstance().getAuthorizationCode(
        id,
        password,
        complexId
      );
      if (loginResult) {
        try {
          let searchParams = new URL(location.href).searchParams;
          if (
            searchParams.get("client_id") == "client-kt" &&
            searchParams.get("redirect_uri") &&
            searchParams.get("response_type")
          ) {
            location.href =
              searchParams.get("redirect_uri") +
              "?code=" +
              loginResult.data.authorizationCode;
          }

          if (
            searchParams.get("client_id") == "client-kakao" &&
            searchParams.get("redirect_uri") &&
            searchParams.get("response_type") &&
            searchParams.get("state")
          ) {
            location.href =
              searchParams.get("redirect_uri") +
              "?code=" +
              loginResult.data.authorizationCode +
              "&state=" +
              searchParams.get("state");
          }

          if (
            searchParams.get("client_id") == "client-smartthings" &&
            searchParams.get("redirect_uri") &&
            searchParams.get("response_type") &&
            searchParams.get("state")
          ) {
            location.href =
              searchParams.get("redirect_uri") +
              "?code=" +
              loginResult.data.authorizationCode +
              "&state=" +
              searchParams.get("state");
          }

        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  async created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    this.searchParams = new URL(location.href).searchParams;
    body.classList.remove("bg-gray-100");
    if (searchParams.get("client_id") == "client-smartthings") {
        // let complexList = await ApiManager.getInstance().getComplexList();
        // if (complexList) {
        //     this.options = [];
        //     for (let complex of complexList.data) {
        //         complex.
        //         this.options.push({
        //             value: complex.complexId,
        //             text: complex.complexName,
        //         });
        //     }
        // }
        // let complex = await ApiManager.getInstance().getComplexByPartnerComplexId(searchParams.get("appId"));
        // if (complex) {
        //     this.options = [];
        //     this.options.push({
        //         value: complex.complexId,
        //         text: complex.complexName,
        //     });
        // }
        // this.options = [];
        // this.options.push({
        //     value: "000000",
        //     text: "complex.complexName",
        // });
        
        let complexList = await ApiManager.getInstance().getComplexList();
        if (complexList) {
            this.options = [];
            for (let complex of complexList.data) {
                this.options.push({
                    value: complex.complexId,
                    text: complex.complexName,
                });
            }
        }
    }
    else {
        let complexList = await ApiManager.getInstance().getComplexList();
        if (complexList) {
            this.options = [];
            for (let complex of complexList.data) {
                this.options.push({
                    value: complex.complexId,
                    text: complex.complexName,
                });
            }
        }
    }
  },
  mounted() {},
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
