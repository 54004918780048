import 'dotenv/config';
import axios from 'axios';
import { env } from 'process';
import { GWDistPhase } from '../util/constants';

const API_LOCALHOST = "http://localhost:8090";
//const API_HOST = "https://ismarthome-api-dev.naviensmarthome.com";
const ApiHostList = {
    Dev: "https://ismarthome-api-dev.naviensmarthome.com",
    Prod: "https://ismarthome-api.naviensmarthome.com"
};

export const API_HOST = ApiHostList[(env.DIST_ENV ? env.DIST_ENV : GWDistPhase.DEVEL)];

const API = {
    COMPLEX: () => { return "/api/complexes" },
    COMPLEX_METRIC: (complexId) => { return `/api/complexes/${complexId}/metrics` },
    COMPLEX_HEALTH: (complexId) => { return `/api/complexes/${complexId}/health` },
    HOME: (complexId) => { return `/api/complexes/${complexId}/homes` },
    USER: () => { return "/api/users" },
    SUPERUSER: () => { return "/api/superusers" },
    DEVICE: () => { return "/api/devices" },
    OAUTH_CODE: () => { return "/api/oauth/code" },
    COMPLEX_PARTNER: () => { return `/api/complexes/${complexId}`}
}

export class ApiManager {
    static getInstance() {
        return ApiManager.instance ? ApiManager.instance : ApiManager.instance = new ApiManager();
    }

    constructor() {
        this.axios = axios.create({
            baseURL: API_HOST
        });
        this.accessToken = undefined;
    }

    async getComplexList() {
        const res = await this.axios.get(API.COMPLEX(), { headers: { Authorization: this.accessToken } });
        if (res.status == 200)
            return res.data;
        return undefined;
    }

    async getComplexMetrics(id) {
        const res = await this.axios.get(API.COMPLEX_METRIC(id), { headers: { Authorization: this.accessToken } });
        if (res.status == 200)
            return res.data;
        return undefined;
    }

    async getComplexHealth(id) {
        const res = await this.axios.get(API.COMPLEX_HEALTH(id), { headers: { Authorization: this.accessToken } });
        if (res.status == 200)
            return res.data;
        return undefined;
    }

    async getAuthorizationCode(userId, password, complexId) {
        const res = await this.axios.post(API.OAUTH_CODE(), { userId: userId, password: password, complexId: complexId });
        if (res.status == 200)
            return res.data;
        return undefined;
    }
    async getComplexByPartnerComplexId(partnerComplexId) {
        const res = await this.axios.get(API.COMPLEX_PARTNER(partnerComplexId), { headers: { Authorization: this.accessToken } });
        if (res.status == 200)
            return res.data;
        return undefined;
    }
}

