<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      :style="{
        'background-image': backgroundImage,
        'background-position': 'center',
      }"
    >
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <p class="text-lead text-white">
              iSmartHome 관리자 사이트에 로그인하세요!<br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8 mx-auto">
          <div class="card z-index-0">
            <div class="pb-0 card-header text-start">
              <h4 class="font-weight-bolder">Sign In</h4>
              <p class="mb-0">Enter your email and password to sign in</p>
            </div>
            <div class="card-body">
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Account"
                  v-model="id"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <argon-switch id="rememberMe">Remember me</argon-switch>
              <div class="text-center">
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="primary"
                  fullWidth
                  size="lg"
                  @click="login(id, password)"
                  >Sign in</argon-button
                >
              </div>
            </div>
            <div class="px-1 pt-0 text-center card-footer px-lg-2">
              <p class="text-sm mt-3 mb-0">
                Forgot password?
                <a href="/signup" class="text-primary font-weight-bolder"
                  >Reset Password</a
                >
              </p>
              <p class="text-sm mt-3 mb-0">
                Don't have an account?
                <a href="/signup" class="text-primary font-weight-bolder"
                  >Sign up</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { ApiManager } from "@/api/apiManager";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      id: undefined,
      password: undefined,
    };
  },
  computed: {
    backgroundImage: function () {
      return `url(${require("../assets/img/logos/ismarthome-logo2.png")})`;
    },
  },
  methods() {
    login: async (id, password) => {
      let result = await ApiManager.getInstance().login(id, password);
      if (result) {
      }
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
