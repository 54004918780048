<template>
  <canvas ref="chart" class="chart-canvas w-100 h-70"></canvas>
  <div
    ref="legend"
    class="d-flex flex-wrap align-items-center justify-content-center mh-30"
  ></div>
</template>
<script>
import Chart from "chart.js/auto";
export default {
  name: "pie-chart",
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    dataset: function (newVal, oldVal) {
      // watch it
      if (this.chart) {
        this.chart.update();
      }
    },
  },
  props: {
    chartName: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    backgroundColors: {
      type: Array,
      required: true,
    },
    dataset: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateDataset: function (dataset) {
      this.chart.data.datasets[0].data = dataset;
      this.chart.update();
    },
    resetLabels: function () {
      for (let child of this.$refs.legend.childNodes) {
        child.classList.remove("text-bolder");
      }
    },
  },
  mounted: function () {
    let chart = this.$refs.chart;
    this.chart = Object.seal(
      new Chart(chart, {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.chartName,
              backgroundColor: this.backgroundColors,
              data: this.dataset,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              text: this.chartName,
              display: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "#fff",
          },
        },
      })
    );

    for (let i = 0; i < this.labels.length; i++) {
      const div = document.createElement("div");
      div.classList.add("d-flex");
      div.classList.add("align-items-center");

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = this.backgroundColors[i];
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "10px";
      boxSpan.style.width = "10px";
      boxSpan.classList.add("ms-1");

      // Text
      const textContainer = document.createElement("span");
      textContainer.classList.add("text-xs");
      textContainer.classList.add("ms-1");
      textContainer.classList.add("me-2");
      textContainer.innerText = this.labels[i];

      div.appendChild(boxSpan);
      div.appendChild(textContainer);
      this.$refs.legend.appendChild(div);
    }
  },
};
</script>
