<template>
  <div class="container-fluid py-2">
    <div class="card shadow">
      <div class="card-header pb-0">Complex List</div>
      <div class="card-body">
        <div class="card-body">
          <!-- <form role="form"> -->
          <div class="mx-auto">
            <div class="form-group">
              <b-form-select
                class="form-control form-control-lg"
                v-model="selected"
                :options="options"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid py-2">
    <div class="card shadow" style="min-height: 100vh">
      <iframe src="https://106.255.253.59:8998" class="card-body"> </iframe>
    </div>
  </div>
  <div class="container-fluid py-2">
    <div class="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-4 gx-0">
      <card-complex
        :key="i"
        v-for="(complex, i) in complexList"
        :complexId="complex.complexId"
        :complexName="complex.complexName"
        :constructorName="complex.constructorName"
        :address="complex.address"
        :ip="complex.ip"
        :itsName="complex.itsName"
        :serverType="complex.serverType"
        :ref="complex.complexId"
        @onPropsUpdated="onPropsUpdated"
        :style="{ display: complex.hidden ? 'none' : 'block' }"
      />
    </div>
  </div>
</template>
<script>
import { ApiManager } from "@/api/apiManager";

export default {
  name: "complex-diagnostic",
  components: {},
  data() {
    return {
      selected: null,
      options: [],
    };
  },
  methods: {},
  async created() {
    this.$store.state.showFooter = false;
    let complexList = await ApiManager.getInstance().getComplexList();
    if (complexList) {
      this.options = [];
      for (let complex of complexList.data) {
        this.options.push({
          value: complex.complexId,
          text: complex.complexName,
        });
      }
    }
  },
  updated() {},
  beforeUnmount() {},
};
</script>
