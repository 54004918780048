<template>
  <div class="container-fluid py-2">
    <div class="card shadow">
      <div class="card-header pb-0">Summary</div>
      <div class="card-body">
        <div class="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-4">
          <div class="col mh-100">
            <pie-chart
              ref="regionChart"
              chartName="지역 분포"
              :labels="[
                '서울',
                '경기',
                '인천',
                '강원',
                '충북',
                '세종',
                '충남',
                '대전',
                '경북',
                '대구',
                '울산',
                '부산',
                '경남',
                '전북',
                '전남',
                '광주',
                '제주',
                '미분류',
              ]"
              :backgroundColors="[
                '#801f4f',
                '#d63384',
                '#e685b5',
                '#198754',
                '#997404',
                '#ffc107',
                '#ffda6a',
                '#fff3cd',
                '#58151c',
                '#842029',
                '#dc3545',
                '#ea868f',
                '#f8d7da',
                '#084298',
                '#0d6efd',
                '#9ec5fe',
                '#fd7e14',
                '#adb5bd',
              ]"
              :dataset="Array(18).fill(0)"
            />
          </div>
          <div class="col mh-100">
            <pie-chart
              ref="connectionChart"
              chartName="연결 상태"
              :labels="['미개통', '연결확인', '연결불가']"
              :backgroundColors="['#adb5bd', '#172b4d', 'red']"
              :dataset="Array(3).fill(0)"
            />
          </div>
          <div class="col mh-100">
            <pie-chart
              ref="selectedMetricsStatusChart"
              chartName="서버 상태"
              :labels="['수집불가', '정상', '경고', '위험', '이상']"
              :backgroundColors="[
                '#adb5bd',
                '#172b4d',
                'yellow',
                'orange',
                'red',
              ]"
              :dataset="Array(5).fill(0)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid py-2">
    <div class="card shadow">
      <div class="card-header pb-0">Filters</div>
      <div class="card-body">
        <div
          class="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-4 align-items-center"
        >
          <div class="col h-100">
            <div class="d-flex align-items-center">
              <span class="flex-fill">주소</span>
              <select
                v-model="filters.selectedSido"
                class="flex-fill ms-1"
                placeholder="filter by sido name"
                @change="onFilterChanged"
              >
                <option
                  :key="i"
                  v-for="(option, i) in sidoSelectOptions"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col h-100">
            <div class="d-flex align-items-center">
              <span class="flex-fill">연결상태 분류</span>
              <select
                v-model="filters.selectedConnectionStatus"
                class="flex-fill ms-1"
                placeholder="filter by sido name"
                @change="onFilterChanged"
              >
                <option
                  :key="i"
                  v-for="(option, i) in connectionSelectOptions"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col h-100">
            <div class="d-flex align-items-center">
              <span class="flex-fill">서버상태 분류</span>
              <select
                v-model="filters.selectedMetricsStatus"
                class="flex-fill ms-1"
                placeholder="filter by sido name"
                @change="onFilterChanged"
              >
                <option
                  :key="i"
                  v-for="(option, i) in metricsSelectOptions"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col h-100">
            <div class="d-flex align-items-center">
              <span class="flex-fill">단지이름 검색</span>
              <input
                class="flex-fill ms-1"
                type="search"
                v-model="filters.searchKeyword"
                @input="onFilterChanged"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid py-2">
    <div class="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-4 gx-0">
      <card-complex
        :key="i"
        v-for="(complex, i) in complexList"
        :complexId="complex.complexId"
        :complexName="complex.complexName"
        :constructorName="complex.constructorName"
        :address="complex.address"
        :ip="complex.ip"
        :itsName="complex.itsName"
        :serverType="complex.serverType"
        :ref="complex.complexId"
        @onPropsUpdated="onPropsUpdated"
        :style="{ display: complex.hidden ? 'none' : 'block' }"
      />
    </div>
  </div>
</template>
<script>
/*
TODO
단지 통계
*/

import CardComplex from "./components/CardComplex.vue";
import PieChart from "./components/PieChart.vue";
import { ApiManager } from "@/api/apiManager";

export default {
  name: "dashboard",
  components: {
    CardComplex,
    PieChart,
  },
  data() {
    return {
      complexList: [],
      sidoSelectOptions: [
        { value: "0", text: "전국" },
        { value: "1", text: "서울" },
        { value: "2", text: "경기도" },
        { value: "3", text: "인천" },
        { value: "4", text: "강원" },
        { value: "5", text: "충북" },
        { value: "6", text: "세종" },
        { value: "7", text: "충남" },
        { value: "8", text: "대전" },
        { value: "9", text: "경북" },
        { value: "10", text: "대구" },
        { value: "11", text: "울산" },
        { value: "12", text: "부산" },
        { value: "13", text: "경남" },
        { value: "14", text: "전북" },
        { value: "15", text: "전남" },
        { value: "16", text: "광주" },
        { value: "17", text: "제주" },
        { value: "18", text: "미분류" },
      ],
      sidoZipCode: [
        0, 9, 20, 23, 26, 29, 30, 33, 35, 40, 43, 45, 49, 53, 56, 60, 62, 63,
      ],
      connectionSelectOptions: [
        { value: "0", text: "전체" },
        { value: "1", text: "미개통" },
        { value: "2", text: "연결확인" },
        { value: "3", text: "연결불가" },
      ],
      metricsSelectOptions: [
        { value: "0", text: "전체" },
        { value: "1", text: "수집불가" },
        { value: "2", text: "정상" },
        { value: "3", text: "경고" },
        { value: "4", text: "위험" },
        { value: "5", text: "이상" },
      ],
      filters: {
        searchKeyword: "",
        selectedSido: "0",
        selectedConnectionStatus: "0",
        selectedMetricsStatus: "0",
      },
    };
  },
  methods: {
    onFilterChanged: function () {
      for (let complex of this.complexList) {
        if (
          this.filters.selectedSido != null &&
          this.filters.selectedSido != "0"
        ) {
          complex.hidden = !this.isSidoMatch(complex);
        } else {
          complex.hidden = false;
        }

        if (!complex.hidden && this.filters.searchKeyword) {
          complex.hidden = !this.isKeywordMatch(complex);
        }

        if (!complex.hidden && this.filters.selectedConnectionStatus != "0") {
          complex.hidden = !this.isConnectionMatch(
            complex,
            this.filters.selectedConnectionStatus
          );
        }

        if (!complex.hidden && this.filters.selectedMetricsStatus != "0") {
          complex.hidden = !this.isMetricsStatusMatch(
            complex,
            this.filters.selectedMetricsStatus
          );
        }
      }
    },
    isKeywordMatch: function (complex) {
      if (
        this.filters.searchKeyword &&
        complex.complexName.includes(this.filters.searchKeyword)
      ) {
        return true;
      }
      return false;
    },
    isSidoMatch: function (complex) {
      if (this.filters.selectedSido == "0") {
        return true;
      } else if (
        this.filters.selectedSido == "18" &&
        isNaN(parseInt(complex.zipCode.substring(0, 2)))
      ) {
        // unknown
        return true;
      } else {
        let min = this.sidoZipCode[this.filters.selectedSido - 1];
        let max = this.sidoZipCode[this.filters.selectedSido];
        let code = parseInt(complex.zipCode.substring(0, 2));
        if (!isNaN(code) && min < code && code <= max) {
          return true;
        }
      }
      return false;
    },
    isConnectionMatch: function (complex, want) {
      switch (want) {
        case "1":
          if (complex.ip == undefined || complex.ip == "") return true;
          break;
        case "2":
          if (complex.isConnected) return true;
          break;
        case "3":
          // temporary blocked
          if (
            complex.complexId == "100049" ||
            complex.complexId == "100048" ||
            complex.complexId == "100047" ||
            complex.complexId == "100090" ||
            complex.complexId == "100076" ||
            complex.complexId == "100067" ||
            complex.complexId == "100066" ||
            complex.complexId == "100065"
          )
            return false;
          if (!complex.isConnected) return true;
          break;
      }
      return false;
    },
    isMetricsStatusMatch: function (complex, want) {
      switch (want) {
        case "1":
          if (
            complex.metrics == undefined ||
            complex.metrics.cpu == undefined ||
            complex.metrics.memory == undefined ||
            complex.metrics.cDrive == undefined ||
            complex.metrics.dDrive == undefined
          ) {
            return true;
          }
          break;

        case "2":
          if (
            complex.metrics &&
            complex.metrics.cpu < 70 &&
            complex.metrics.memory < 70 &&
            complex.metrics.cDrive < 70 &&
            complex.metrics.dDrive < 70
          ) {
            return true;
          }
          break;

        case "3":
          if (
            complex.metrics &&
            ((70 <= complex.metrics.cpu && complex.metrics.cpu < 80) ||
              (70 <= complex.metrics.memory && complex.metrics.memory < 80) ||
              (70 <= complex.metrics.cDrive && complex.metrics.cDrive < 80) ||
              (70 <= complex.metrics.dDrive && complex.metrics.dDrive < 80))
          ) {
            return true;
          }
          break;

        case "4":
          if (
            complex.metrics &&
            ((80 <= complex.metrics.cpu && complex.metrics.cpu < 90) ||
              (80 <= complex.metrics.memory && complex.metrics.memory < 90) ||
              (80 <= complex.metrics.cDrive && complex.metrics.cDrive < 90) ||
              (80 <= complex.metrics.dDrive && complex.metrics.dDrive < 90))
          ) {
            return true;
          }
          break;

        case "5":
          if (
            complex.metrics &&
            (90 <= complex.metrics.cpu ||
              90 <= complex.metrics.memory ||
              90 <= complex.metrics.cDrive ||
              90 <= complex.metrics.dDrive)
          ) {
            return true;
          }
          break;
      }
      return false;
    },
    onPropsUpdated: function (updatedProps) {
      for (let complex of this.complexList) {
        if (complex.complexId == updatedProps.complexId) {
          if (updatedProps.isConnected != undefined)
            complex.isConnected = updatedProps.isConnected;
          if (updatedProps.metrics != undefined)
            complex.metrics = updatedProps.metrics;
          this.updateChart();
        }
      }
    },
    updateChart: function () {
      let regionData = Array(this.sidoZipCode.length).fill(0);
      let connectionData = Array(3).fill(0);
      let selectedMetricsStatusData = Array(5).fill(0);

      for (let complex of this.complexList) {
        // region chart
        let code = parseInt(complex.zipCode.substring(0, 2));
        if (isNaN(code)) {
          regionData[17]++;
        } else {
          for (let i = 1; i < this.sidoZipCode.length; i++) {
            let min = this.sidoZipCode[i - 1];
            let max = this.sidoZipCode[i];
            if (min < code && code <= max) {
              regionData[i - 1]++;
              break;
            }
          }
        }

        // connection chart
        if (complex.isConnected == undefined) {
          connectionData[0]++;
        } else if (complex.isConnected) {
          connectionData[1]++;
        } else {
          connectionData[2]++;
        }

        // metric status chart
        if (
          complex.metrics == undefined ||
          complex.metrics.cpu == undefined ||
          complex.metrics.memory == undefined ||
          complex.metrics.cDrive == undefined ||
          complex.metrics.dDrive == undefined
        ) {
          selectedMetricsStatusData[0]++;
        } else if (
          complex.metrics.cpu >= 90 ||
          complex.metrics.memory >= 90 ||
          complex.metrics.cDrive >= 90 ||
          complex.metrics.dDrive >= 90
        ) {
          selectedMetricsStatusData[4]++;
        } else if (
          complex.metrics.cpu >= 80 ||
          complex.metrics.memory >= 80 ||
          complex.metrics.cDrive >= 80 ||
          complex.metrics.dDrive >= 80
        ) {
          selectedMetricsStatusData[3]++;
        } else if (
          complex.metrics.cpu >= 70 ||
          complex.metrics.memory >= 70 ||
          complex.metrics.cDrive >= 70 ||
          complex.metrics.dDrive >= 70
        ) {
          selectedMetricsStatusData[2]++;
        } else {
          selectedMetricsStatusData[1]++;
        }
      }
      this.$refs.regionChart.updateDataset(regionData);
      this.$refs.connectionChart.updateDataset(connectionData);
      this.$refs.selectedMetricsStatusChart.updateDataset(
        selectedMetricsStatusData
      );
    },
  },
  async created() {
    this.$store.state.showFooter = false;
    let result = await ApiManager.getInstance().getComplexList();
    if (result) this.complexList = result.data;
    this.$store.state.showFooter = true;
  },
  updated() {
    this.updateChart();
  },
  beforeUnmount() {},
};
</script>
